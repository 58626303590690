import CustomHead from "@app/layouts/head";
import HomeScreen from "@app/screens/home";
import { Inter } from "next/font/google";

const inter = Inter({ subsets: ["latin"] });

export default function Home() {
  return (
    <>
      <CustomHead
        title="Industrial Automation Solutions | Your Trusted Partner"
        description="Industrial Automation and Solutions provides state-of-the-art automation equipment, services, and turnkey projects to enhance productivity."
        keywords="Industrial Automation, Automation Services, Factory Automation, SCADA, PLC Programming"
        canonicalHref="https://www.industrialautomationandsolutions.com/"
      />
      <HomeScreen />
    </>
  );
}
