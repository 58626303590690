import Head from "next/head";

const CustomHead = ({ title, description, keywords, canonicalHref }) => {
  return (
    <Head>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta name="author" content="Industrial Automation and Solutions" />
      <meta name="robots" content="index, follow" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={canonicalHref} />
      {/* <meta property="og:image" content="/og-image.jpg" /> */}
      {/* <meta name="twitter:card" content="summary_large_image" /> */}
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      {/* <meta name="twitter:image" content="/twitter-image.jpg" /> */}
      <link rel="icon" href="/favicon.ico.png" />
      <link rel="canonical" href={canonicalHref} />
    </Head>
  );
};

export default CustomHead;
